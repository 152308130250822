import { publish } from "../helper/pubsub";
import { PUB_SUB_EVENTS } from "../helper/constants";

class VariantSelects extends HTMLElement {
    constructor() {
      super();
      this.crossell = this.getAttribute('crosssell');
      this.addEventListener('change', this.onVariantChange);
      
    }
  
    onVariantChange() {
      this.updateOptions();
      this.updateMasterId();
      this.updatePickupAvailability();
      this.removeErrorMessage();
      this.updateVariantStatuses();
      this.updateVariantTitle();
      this.updateSliderPosition();
  
      if (!this.currentVariant) {
        this.toggleAddButton(true, 'Sold Out', true);
        this.setUnavailable();
      } else {
        this.updateURL();
        this.updateVariantInput();
        this.renderProductInfo();
        this.updateShareUrl();
        this.updateVariantTitle();
        this.toggleAddButton(false, 'Buy It Now', true);
      }
    }
  
    updateOptions() {
      this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
    }

  
    updateMasterId() {
      this.currentVariant = this.getVariantData().find((variant) => {
        return !variant.options
          .map((option, index) => {
            return this.options[index] === option;
          })
          .includes(false);
      });
    }

    updateSliderPosition() {
      if(!this.crossell) {
      const slider = document.querySelector('product-media');
      if(this.currentVariant.featured_media) slider.slider.go(this.currentVariant.featured_media.position - 1);
      }
    }
  
    updateVariantTitle() {
      const selection = this.querySelector('legend') !== null;
      if( selection ) {
        this.querySelector('legend').firstElementChild.textContent = this.currentVariant.title;
      }
      const mainProductPrice = document.querySelector('.js-main-price').querySelector('.price-item--regular');
      mainProductPrice.textContent = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: Shopify.currency.active,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(this.currentVariant.price / 100);
    }
  
    updateURL() {
      if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
      window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
    }
  
    updateShareUrl() {
      const shareButton = document.getElementById(`Share-${this.dataset.section}`);
      if (!shareButton || !shareButton.updateUrl) return;
      shareButton.updateUrl(`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`);
    }
  
    updateVariantInput() {
      const productForm = this.closest(
        `form`
      );
        console.log(productForm)
        const input = productForm.querySelector('input[name="id"]');
        input.value = this.currentVariant.id;
        input.dispatchEvent(new Event('change', { bubbles: true }));
    }
  
    updateVariantStatuses() {
      const selectedOptionOneVariants = this.variantData.filter(
        (variant) => this.querySelector(':checked').value === variant.option1
      );
      const inputWrappers = [...this.querySelectorAll('.product-form__input')];
      inputWrappers.forEach((option, index) => {
        if (index === 0) return;
        const optionInputs = [...option.querySelectorAll('input[type="radio"], option')];
        const previousOptionSelected = inputWrappers[index - 1].querySelector(':checked').value;
        const availableOptionInputsValue = selectedOptionOneVariants
          .filter((variant) => variant.available && variant[`option${index}`] === previousOptionSelected)
          .map((variantOption) => variantOption[`option${index + 1}`]);
        this.setInputAvailability(optionInputs, availableOptionInputsValue);
      });
    }
  
    setInputAvailability(listOfOptions, listOfAvailableOptions) {
      listOfOptions.forEach((input) => {
        if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
          input.innerText = input.getAttribute('value');
        } else {
          input.innerText = window.variantStrings.unavailable_with_option.replace('[value]', input.getAttribute('value'));
        }
      });
    }
  
    updatePickupAvailability() {
      const pickUpAvailability = document.querySelector('pickup-availability');
      if (!pickUpAvailability) return;
  
      if (this.currentVariant && this.currentVariant.available) {
        pickUpAvailability.fetchAvailability(this.currentVariant.id);
      } else {
        pickUpAvailability.removeAttribute('available');
        pickUpAvailability.innerHTML = '';
      }
    }
  
    removeErrorMessage() {
      const section = this.closest('section');
      if (!section) return;
  
      const productForm = section.querySelector('product-form');
      if (productForm) productForm.handleErrorMessage();
    }
  
    renderProductInfo() {
      const requestedVariantId = this.currentVariant.id;
      const sectionId = this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section;
  
      fetch(
        `${this.dataset.url}?variant=${requestedVariantId}&section_id=${
          this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
        }`
      )
        .then((response) => response.text())
        .then((responseText) => {
          // prevent unnecessary ui changes from abandoned selections
          if (this.currentVariant.id !== requestedVariantId) return;
  
          const html = new DOMParser().parseFromString(responseText, 'text/html');
          const destination = document.getElementById(`price-${this.dataset.section}`);
          const source = html.getElementById(
            `price-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const skuSource = html.getElementById(
            `Sku-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const skuDestination = document.getElementById(`Sku-${this.dataset.section}`);
          const inventorySource = html.getElementById(
            `Inventory-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const inventoryDestination = document.getElementById(`Inventory-${this.dataset.section}`);
  
          if (source && destination) destination.innerHTML = source.innerHTML;
          if (inventorySource && inventoryDestination) inventoryDestination.innerHTML = inventorySource.innerHTML;
          if (skuSource && skuDestination) {
            skuDestination.innerHTML = skuSource.innerHTML;
            skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
          }
  
          const price = document.getElementById(`price-${this.dataset.section}`);
  
          if (price) price.classList.remove('visibility-hidden');
  
          if (inventoryDestination)
            inventoryDestination.classList.toggle('visibility-hidden', inventorySource.innerText === '');
  
          const addButtonUpdated = html.getElementById(`ProductSubmitButton-${sectionId}`);

          this.toggleAddButton(
            addButtonUpdated ? addButtonUpdated.hasAttribute('disabled') : true,
            window.variantStrings.soldOut
          );
  
          publish(PUB_SUB_EVENTS.variantChange, {
            data: {
              sectionId,
              html,
              variant: this.currentVariant,
            },
          });
        });
    }
  
    toggleAddButton(disable = true, text, modifyClass = true) {
      const productForm = document.getElementById(`product-form-${this.dataset.section}`);
      if (!productForm) return;
      const addButton = productForm.querySelector('[name="add"]');
      const addButtonText = productForm.querySelector('[name="add"] > span');
      if (!addButton) return;
  
      if (disable) {
        addButton.setAttribute('disabled', 'disabled');
        if (text) addButtonText.textContent = text;
      } else {
        addButton.removeAttribute('disabled');
        addButtonText.textContent = window.variantStrings.addToCart;
      }
  
      if (!modifyClass) return;
    }
  
    setUnavailable() {
      const button = document.getElementById(`product-form-${this.dataset.section}`);
      const addButton = button.querySelector('[name="add"]');
      const addButtonText = button.querySelector('[name="add"] > span');
      const price = document.getElementById(`price-${this.dataset.section}`);
      const inventory = document.getElementById(`Inventory-${this.dataset.section}`);
      const sku = document.getElementById(`Sku-${this.dataset.section}`);
  
      if (!addButton) return;
      addButtonText.textContent = window.variantStrings.unavailable;
      if (price) price.classList.add('visibility-hidden');
      if (inventory) inventory.classList.add('visibility-hidden');
      if (sku) sku.classList.add('visibility-hidden');
    }
  
    getVariantData() {
      this.variantData = this.variantData || JSON.parse(this.querySelector('[type="application/json"]').textContent);
      return this.variantData;
    }
  }
  
  customElements.define('variant-selects', VariantSelects);
  
  class VariantRadios extends VariantSelects {
    constructor() {
      super();
      this.updateOptions();
      this.updateMasterId();
      this.updateSliderPosition();
    }
  
    setInputAvailability(listOfOptions, listOfAvailableOptions) {
      listOfOptions.forEach((input) => {
        if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
          input.classList.remove('disabled');
        } else {
          input.classList.add('disabled');
        }
      });
    }
  
    updateOptions() {
      const fieldsets = Array.from(this.querySelectorAll('fieldset'));
      this.options = fieldsets.map((fieldset) => {
        return Array.from(fieldset.querySelectorAll('input')).find((radio) => radio.checked).value;
      });
    }
  }

  customElements.define('variant-radios', VariantRadios);